<template>
  <div class="pb-16 mb-16">
    <div class="overline primary--text">{{ $t('About the fundus')}}</div>
    <!-- <v-row>
      <v-col class="pl-2 pt-2" cols="12">
        col!
      </v-col>
    </v-row> -->

    <v-card
      class="transparent user-select"
      :class="$vuetify.breakpoint.xs ? 'fill-height ma-0 pa-4' : $vuetify.breakpoint.mdAndUp ? 'mx-auto my-4 mt-12 pa-8' : 'mx-auto my-4 mt-0 pa-8'"
      :max-width="$vuetify.breakpoint.smAndUp ? 666 : 6666"
      flat
    >
      <v-card-title class="justify-center orange--text italics " style="word-break: initial;">
        <img class="op-12" :class="$vuetify.theme.dark ? 'invert' : 'darken-3'" src="logo.svg" :style="$vuetify.breakpoint.smAndUp ? 'height:4em' : 'height:2em'">
      </v-card-title>
      <v-card-text class="italics text-center">
        <Loader :hideLoading="true" :interval="1111"></Loader>
      </v-card-text>
      <v-card-subtitle class="text--center">
        {{ $t('A huge collection of big, small, exciting and boring props for film & TV') }}
      </v-card-subtitle>
    </v-card>

    <v-card
      class="transparent user-select"
      :class="$vuetify.breakpoint.xs ? 'fill-height ma-0 pa-4' : $vuetify.breakpoint.mdAndUp ? 'mx-auto my-4 mt-12 pa-8' : 'mx-auto my-4 mt-0 pa-8'"
      :max-width="$vuetify.breakpoint.smAndUp ? 666 : 6666"
      flat
    >
      <v-card-title class="justify-center">
        {{ $t('About us') }}
      </v-card-title>
      <v-card-text class="">
        <p>
          Text über uns<br>
          Text über uns<br>
          Text über uns<br>
          Text über uns<br>
          Text über uns<br>
          Text über uns<br>
          Text über uns<br>
          Text über uns<br>
          Text über uns<br>
        </p>
      </v-card-text>

      <v-card-title class="justify-center">{{  $t('FAQ') }}</v-card-title>
        <v-card-text class="">
        <v-row justify="center" class="mb-4">
          <v-expansion-panels accordion v-model="faqIndex">
            <v-expansion-panel
              v-for="(faq,i) in faqs"
              :key="i"
            >
              <v-expansion-panel-header
                style="min-height:initial;"
                :class="[
                  faqIndex === i ? 'primary white--text' :
                  $vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-3'
                ]"
              >
                {{faq.question}}
              </v-expansion-panel-header>
              <v-expansion-panel-content class="grey" :class="$vuetify.theme.dark ? 'darken-4' : 'lighten-4'">
                <p class="pt-4" v-html="faq.answer"></p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      class="transparent user-select"
      :class="$vuetify.breakpoint.xs ? 'fill-height ma-0 pa-4' : $vuetify.breakpoint.mdAndUp ? 'mx-auto my-4 mt-12 pa-8' : 'mx-auto my-4 mt-0 pa-8'"
      :max-width="$vuetify.breakpoint.smAndUp ? 666 : 6666"
      flat
    >
      <v-card-title class="justify-center">
        {{  $t('Whos behind all this?') }}
      </v-card-title>
      <v-card-text class="">
        <v-img
          class="rounded"
          alt=""
          :src="require('@/assets/gruppenfoto.jpg')"
        ></v-img>

        <v-list three-line class="pa-0">
          <v-list-item class="pa-0">
            <!-- <v-list-item-avatar v-if="$vuetify.breakpoint.mdAndUp" style="display: block" color="grey darken-3" size="125">
              <v-img
                alt=""
                :src="require('@/assets/gruppenfoto.jpg')"
              ></v-img>
            </v-list-item-avatar> -->
            <v-list-item-content class="pa-2">
              <v-list-item-title>
                {{ $t('Fundus Ça Tourne') }}
              </v-list-item-title>
              <v-list-item-subtitle class="grey--text">
                {{ $t('Association in Zurich') }}
              </v-list-item-subtitle>

              <div class="my-4" style="line-height:1.75em;" :style="$vuetify.breakpoint.lgAndUp ? 'column-count: 2; column-gap: 0.5rem;' : ''">
                <a class="decorated" href="https://noemibaldelli.com" target="_blank">Noemi Baldelli</a><br>
                <a class="decorated" href="http://dorisbaumgartner.com" target="_blank">Doris Baumgartner</a><br>
                <a class="decorated" href="https://ssfv.ch/de/crew/crew-suche/michael-baumgartner-375" target="_blank">Michael Baumgartner</a><br>
                <a class="decorated" href="https://chloebourgogne.ch" target="_blank">Chloé Bourgogne</a><br>
                <!-- <a class="decorated" href="https://ssfv.ch/de/crew/crew-suche/su-erdt-520" target="_blank">Su Erdt</a><br> -->
                <a class="decorated" href="https://fluescher.ch" target="_blank">Fabian Lüscher</a><br>
                <!-- <a class="decorated" href="https://ssfv.ch/de/crew/crew-suche/nina-mader-1872" target="_blank">Nina Mader</a><br> -->
                <a class="decorated" href="https://ssfv.ch/de/crew/crew-suche/doia-matare-1924" target="_blank">Doia Mataré</a><br>
                <!-- <a class="decorated" href="https://ssfv.ch/de/crew/crew-suche/karin-nyffenegger-2061" target="_blank">Karin Nyffenegger</a><br> -->
                <a class="decorated" href="https://www.crew-united.com/de/Raphael-Schulenburg_506311.html" target="_blank">Raphael Schulenburg</a><br>
                <a class="decorated" href="https://isabellesimmen.com" target="_blank">Isabelle Simmen</a><br>
                <!-- <a class="decorated" href="https://ssfv.ch/de/crew/crew-suche/simon-sramek-2145" target="_blank">Simon Sramek</a><br> -->
                <a class="decorated" href="https://ssfv.ch/de/crew/crew-suche/sophie-stalder-1930" target="_blank">Sophie Stalder</a><br>
                <a class="decorated" href="https://ssfv.ch/de/crew/crew-suche/catharina-strebel-1620" target="_blank">Catharina Strebel</a><br>
                <a class="decorated" href="https://thalmanisch.ch" target="_blank">Pascal Thalman</a><br>
                <a class="decorated" href="http://sarabweingart.ch" target="_blank">Sara B. Weingart</a><br>
                <!-- <a class="decorated" href="https://ni-za.com" target="_blank">Natalina Zainal</a><br> -->
                <!-- <a class="decorated" href="https://mirjamzimmermann.com" target="_blank">Mirjam Zimmerman</a><br> -->
                <a class="decorated" href="https://ssfv.ch/de/crew/crew-suche/beat-zobrist-525" target="_blank">Beat Zobrist</a><br>
              </div>


              <!-- <v-list-item-subtitle class="grey--text mb-4">
                <a href="https://fluescher.ch" class="no-underline" target="_blank">fluescher.ch</a> /
                <a href="https://www.ssfv.ch/de/crew/crew-suche/fabian-luescher-1770" class="no-underline" target="_blank">ssfv.ch</a> /
                <a href="https://www.imdb.com/name/nm5535144" class="no-underline" target="_blank">imdb.com</a>
              </v-list-item-subtitle> -->
              <v-card-text class="ma-0 pa-0">
                <!-- <p>
                  Thank you
                  <a href="https://www.isabellesimmen.com/" class="externalLink no-underline" target="_blank">Isabelle Simmen</a>,
                  <a href="https://www.aronsommer.com/" class="externalLink no-underline" target="_blank">Aron Sommer</a>,
                  Milena,
                  <a href="https://gabi-views.com/" class="externalLink no-underline" target="_blank">Gabi Morales</a> &
                  Madlen Hallauer
                  for testing, feedback and enthusiasm;
                  and szeno chat for the community.
                </p> -->
              </v-card-text>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-text class="pa-0 mt-8 text-center">
          <!-- <p>
            This website is closed source.
            Get in contact if you have questions or want to improve this:
            <br>
            <a href="tel:0787424834" title="call" class="mr-2 no-underline" :class="{'bigMobileButton' : $vuetify.breakpoint.smAndDown}">
              <v-icon color="primary">mdi-phone</v-icon>
            </a>
            <a href="mailto:info@fluescher.ch" title="write email" class="mr-2 no-underline" :class="{'bigMobileButton' : $vuetify.breakpoint.smAndDown}">
              <v-icon color="primary">mdi-email</v-icon>
            </a>
            <a href="https://github.com/falue/dbcatourne" title="github repo" target="_blank" class="mr-2 no-underline" :class="{'bigMobileButton' : $vuetify.breakpoint.smAndDown}">
              <v-icon color="primary">mdi-github</v-icon>
            </a>
          </p>
          <br> -->
          <p name="spenden">
            {{ $t('Donate here') }}:
          </p>
          <v-btn @click="popUpTwint = !popUpTwint" large color="black" class="mr-2">
            <img style="height:2em" :src="require('@/assets/twintLogo.svg')">
          </v-btn>
          <!-- <a href="https://www.buymeacoffee.com/falue" target="_blank">
            <v-btn large color="black" class="pa-0">
              <img style="height:44px" src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png" title="Buy Me A Coffee (Credit card)">
            </v-btn>
          </a> -->
        </v-card-text>

        <v-card-text class="text-center mt-12">
          <!-- This project is open-source under <a href="https://github.com/falue/dbcatourne/blob/main/COPYING" target="_blank">GNU GPLv3</a>.
          <br> -->
          <router-link to="/privacy">Privacy agreement</router-link>
          &middot;
          <router-link to="/terms">Terms & conditions</router-link>
          &middot;
          <a href="https://github.com/falue/dbcatourne/blob/main/COPYING" target="_blank">GNU GPLv3</a>
          <br>
          <span class="pr-1 small">
            Website made with <v-icon small color="rgba(255,0,255,.5)">mdi-heart</v-icon> by
          </span>
          <a href="https://www.telefabi.ch" target="_blank" class="no-underline small"><span class="secondary--text ">telefabi.ch</span></a>
        </v-card-text>
      </v-card-text>
    </v-card>

    <!-- TWINT QR CODE -->
    <v-dialog
      v-model="popUpTwint"
      width="300"
      :light="$vuetify.breakpoint.smAndUp"
    >
    <v-card :ripple="false" :class="$vuetify.breakpoint.smAndUp ? 'white' : ''" @click="$vuetify.breakpoint.mdAndUp ? popUpTwint = false : null">
        <!-- v-if="$vuetify.breakpoint.smAndUp" -->
        <v-card-text class="ma-0 pa-4"
        >
          <v-card-title class="pa-0 justify-center">Scan with TWINT app</v-card-title>
          <img style="width:100%" :src="require('@/assets/twint.svg')">
        </v-card-text>
      <!-- <v-card-text class="ma-0 pa-4" v-if="$vuetify.breakpoint.smAndDown">
        <v-card-title class="pa-0 mb-6 justify-center">Copy to TWINT app</v-card-title>
        Fabian Lüscher
        <Copy data="Fabian Lüscher" dataName="TWINT Name" :opacity="1"></Copy>
        <br>
        078 742 48 34
        <Copy data="0787424834" dataName="TWINT number" :opacity="1"></Copy>
        <br>
        <v-card-subtitle v-if="$vuetify.breakpoint.xs" class="pa-0 mt-6 grey--text">
          (QR Code when visiting with bigger screen)
        </v-card-subtitle>
      </v-card-text> -->
    </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import Copy from '@/components/Copy'
import Loader from "../components/Loader.vue"

  export default {
    name: 'About',
    props: {
      auth: Boolean,
      user: Object,
    },
    components: {
      // Copy,
      Loader,
    },
    
    data () {
      return {
        popUpTwint: false,
        faqIndex: -1,
        faqs: [
          {
            'question': 'Wie kann ich ausleihen bei euch?',
            'answer': `Du musst nicht Mitglied sein. Details siehe <a href="/#/renting">hier</a>.`
          },
          {
            'question': 'Wo seid ihr?',
            'answer': `
                      <span class="italics bolder">Wir sind nur auf Anmeldung da!</span>
                      <br>
                      Standort Fundus:
                      <br>
                      <!-- <a
                      href="https://www.google.com/maps/dir//8152+Opfikon/@47.510398,8.498683,12z/data=!4m9!4m8!1m0!1m5!1m1!1s0x479aa00fd1705689:0x8de903f47f095f0!2m2!1d8.5671098!2d47.4346801!3e0?hl=de&entry=ttu"
                      target="_blank"
                      > -->
                      Opfikon-Glattbrugg
                      <!-- </a> -->

                      <p class="pt-4 pb-2">
                        Für genaue Adresse bitte <a class="decorated" href="mailto:fundus@catourne.ch">Kontakt</a> aufnehmen.
                      </p>
                      <p class="pb-4">
                        Rechnungsadresse:<br>
                        Hermetschloostrasse 70, 8048 Zürich
                      </p>`
          },
          {
            'question': 'Was sind eure Öffnungszeiten?',
            'answer': `<span class="italics bolder">Wir sind nur auf Anmeldung da!</span><br>Details <a href="/#/renting">hier</a>.`
          },
          {
            'question': 'Wie kann ich euch unterstützen?',
            'answer': `Wir freuen uns Über jede Spende.<br>
                      Siehe zuunterst auf dieser Seite.
                      <!--<a href="#spenden">Donate here!</a>-->
                      `
          },
          {
            'question': 'Kann ich Mitglied werden?',
            'answer': `antowrt`
          },
          {
            'question': 'Was für externe Services benutzt diese Seite?',  // What external services do you use for this
            'answer': `
                      Frontend with <a href="https://vuejs.org" target="_blank">Vue.js</a> and <a href="https://vuetifyjs.com/" target="_blank">Vuetify</a></p><p>
                      Backend & Authentication by <a href="https://firebase.google.com/" target="_blank">Google Firebase & Firestore</a></p><p>
                      Dynamic image storage by <a href="https://firebase.google.com/products/storage" target="_blank">Google Firebase Storage</a></p><p>
                      Hosting by <a href="https://hostpoint.ch" target="_blank">hostpoint.ch</a></p><p>
                      Dynamic translations by <a href="https://deepl.com" target="_blank">Deepl.com</a></p><p>
                      Rental backend by <a href="https://rentman.io" target="_blank">rentman.io</a></p><p>
                      Random default user image API by <a href="https://avatars.dicebear.com" target="_blank">dicebear.com</a></p><p>
                      No tracking/analytics tools are being used.
                      `
          },
          /* {
            'question': 'How are these resources curated?',
            'answer': `This list is created & curated by you, the users.
                      </p><p>
                      It is not meant to be a fixed, always correct and up-to-date list. It changes.
                      Double check.
                      </p><p>
                      Its a starting point for your own research.`
          },
          {
            'question': 'What types of resources should be here?',
            'answer': `Every company you've researched that delivers good stuff on time;
                      specialists for all kind of curiosities or skillsets that are hard to find;
                      or someone with a collection of things to rent or buy should be here.
                      </p><p>
                      That said, I'd like to keep this community driven, so if you think this should be different,
                      <a href="mailto:info@fluescher.ch">get in touch</a>.
                      </p><p>
                      This is not a material exchange. At least not yet.
                      </p><p>
                      For now, <span class="overline pink--text" style="line-height:1em">catourne</span>
                      is not meant for short-term one-off exchanges of goods
                      (it CAN be though, <a href="https://github.com/falue/dbcatourne" target="_blank">if you're willing
                      to help</a>).
                      </p><p>Have an old carpet from a project?
                      Maybe post it in our chat,
                      <a href="https://tutti.ch" target="_blank">tutti</a>,
                      or <a href="https://ricardo.ch" target="_blank">ricardo</a>.
                      Have a warehouse full of stuff that fills & empties all the time? Yes please.`
          },
          {
            'question': "What's new? Latest changes?",
            'answer': `See all the <a href="#/updates">updates & issues here</a>, big and small.`
          },
          {
            'question': 'I found incorrect data. What to do?',
            'answer': `If information is outdated here - update the resource, for the others to know!
                      </p><p>
                      You can delete the resource entirely, but sometimes its useful to mark it as
                      "unreliable" and leave a comment why that is. Is the company defunct? Hostile?
                      Owned by murderers?
                      </p><p>
                      No need for others to find out for themselves if you know it already.`
          },
          {
            'question': 'Is functionality X possible?',
            'answer': `Yes. No. Depends. Checkout
                      <a href="https://github.com/falue/dbcatourne/milestone/2" target="_blank">all the ideas out here</a>.
                      The time I need (or you need!) for new features can vary massively, and perhaps not in the ways you imagine.`
          },
          {
            'question': 'What external services do you use for this?',
            'answer': `
                      Frontend by <a href="https://vuejs.org" target="_blank">Vue.js</a> with <a href="https://vuetifyjs.com/" target="_blank">Vuetify</a></p><p>
                      Backend & Authentication by <a href="https://firebase.google.com/" target="_blank">Google Firebase & Firestore</a></p><p>
                      Dynamic image storage by <a href="https://firebase.google.com/products/storage" target="_blank">Google Firebase Storage</a></p><p>
                      Hosting by <a href="https://metanet.ch" target="_blank">metanet.ch</a></p><p>
                      Dynamic translations by <a href="https://deepl.com" target="_blank">Deepl.com</a></p><p>
                      Random default user image API by <a href="https://avatars.dicebear.com" target="_blank">dicebear.com</a></p><p>
                      No tracking/analytics tools are being used.
                      `
          },
          {
            'question': 'I have problems!',
            'answer': `Not really a question, but good grief! I hope you're all right.
                      </p><p>
                      Drop me a <a href="mailto:info@fluescher.ch">message</a> with all the details you can imagine,
                      or even better, join github and
                      <a href="https://github.com/falue/dbcatourne/issues/new" target="_blank">create an issue</a>!
                      Sometimes I can solve things fast. Sometimes not, but I will try hard.`
          }, */
        ],
      }
    },
  }
</script>
